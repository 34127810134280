import React, { Suspense } from 'react';
import { ThemedLayoutContextProvider } from '@refinedev/antd';
import { ThemedHeaderV2 as DefaultHeader } from './header';
import { ThemedSiderV2 as DefaultSider } from './sider';
import { Grid, Layout as AntdLayout, Spin } from 'antd';
import type { RefineThemedLayoutV2Props } from '@refinedev/antd';

export const ThemedLayoutV2: React.FC<RefineThemedLayoutV2Props> = ({
	children,
	Header,
	Sider,
	Title,
	Footer,
	OffLayoutArea,
	initialSiderCollapsed,
}) => {
	const breakpoint = Grid.useBreakpoint();
	const SiderToRender = Sider ?? DefaultSider;
	const HeaderToRender = Header ?? DefaultHeader;
	const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;

	return (
		<ThemedLayoutContextProvider initialSiderCollapsed={initialSiderCollapsed}>
			<AntdLayout style={{ minHeight: '100vh' }}>
				<SiderToRender Title={Title} />
				<AntdLayout>
					<HeaderToRender />
					<Suspense
						fallback={<Spin size="large" style={{ paddingTop: '40px' }} />}
					>
						<AntdLayout.Content>
							<div
								style={{
									minHeight: 360,
									padding: '4px 8px',
								}}
							>
								{children}
							</div>
							{OffLayoutArea && <OffLayoutArea />}
						</AntdLayout.Content>
					</Suspense>
					{Footer && <Footer />}
				</AntdLayout>
			</AntdLayout>
		</ThemedLayoutContextProvider>
	);
};
