import { useState, useEffect } from 'react';
import {
	useGetLocale,
	useSetLocale,
	useGetIdentity,
	useTranslate,
	useList,
	useLogout,
	useNavigation,
} from '@refinedev/core';
import { Link } from 'react-router-dom';
import {
	UserOutlined,
	SettingOutlined,
	LogoutOutlined,
	SearchOutlined,
	DownOutlined,
} from '@ant-design/icons';

import {
	Dropdown,
	Input,
	Avatar,
	Typography,
	Space,
	Grid,
	Row,
	Col,
	AutoComplete,
	Layout as AntdLayout,
	Button,
	theme,
	MenuProps,
	Popover,
} from 'antd';

import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

const { Header: AntdHeader } = AntdLayout;
const { useToken } = theme;
const { Text } = Typography;
const { useBreakpoint } = Grid;

import { IMiniapp, ISection, IIdentity } from 'interfaces';
import { HeaderTitle } from './styled';
import { useConfigProvider } from 'context';
import { IconMoon, IconSun } from 'components/icons';

import { ProfilePage, ChangePasswordPage } from 'pages/accounts';

interface IOptionGroup {
	value: string;
	label: string | React.ReactNode;
}

interface IOptions {
	label: string | React.ReactNode;
	options: IOptionGroup[];
}

export const Header: React.FC = () => {
	const { token } = useToken();
	const { mode, setMode } = useConfigProvider();
	const { i18n } = useTranslation();
	const locale = useGetLocale();
	const changeLanguage = useSetLocale();
	const { data: user } = useGetIdentity<IIdentity>();
	const screens = useBreakpoint();
	const t = useTranslate();
	const { mutate: logout } = useLogout();

	const { edit } = useNavigation();

	const currentLocale = locale();

	const renderTitle = (title: string) => (
		<HeaderTitle>
			<Text style={{ fontSize: '16px' }}>{title}</Text>
			<Link to={`/${title.toLowerCase()}`}>{t('search.more')}</Link>
		</HeaderTitle>
	);

	const renderItem = (title: string, imageUrl: string, link: string) => ({
		value: title,
		label: (
			<Link to={link} style={{ display: 'flex', alignItems: 'center' }}>
				<Avatar size={44} src={imageUrl} style={{ minWidth: '52px' }} />
				<Text style={{ marginLeft: '16px' }}>{title}</Text>
			</Link>
		),
	});

	const [value, setValue] = useState<string>('');
	const [options, setOptions] = useState<IOptions[]>([]);

	const { refetch: refetchMiniapps } = useList<IMiniapp>({
		resource: 'miniapps',
		config: {
			filters: [{ field: 'q', operator: 'contains', value }],
		},
		queryOptions: {
			enabled: false,
			onSuccess: (data) => {
				const orderOptionGroup = data.data.map((item) =>
					renderItem(
						`${item.miniapp_name} / #${item.package_name}`,
						'/images/marker-courier.svg',
						`/miniapps/edit/${item.id}`
					)
				);
				if (orderOptionGroup.length > 0) {
					setOptions((prevOptions) => [
						...prevOptions,
						{
							label: renderTitle('MiniApps'),
							options: orderOptionGroup,
						},
					]);
				}
			},
		},
	});

	const { refetch: refetchSections } = useList<ISection>({
		resource: 'sections',
		config: {
			filters: [{ field: 'q', operator: 'contains', value }],
		},
		queryOptions: {
			enabled: false,
			onSuccess: (data) => {
				const orderOptionGroup = data.data.map((item) =>
					renderItem(
						`${item.section_name} / #${item.id}`,
						'/images/marker-courier.svg',
						`/sections/edit/${item.id}`
					)
				);
				if (orderOptionGroup.length > 0) {
					setOptions((prevOptions) => [
						...prevOptions,
						{
							label: renderTitle('Sections'),
							options: orderOptionGroup,
						},
					]);
				}
			},
		},
	});

	useEffect(() => {
		setOptions([]);
		refetchMiniapps();
		refetchSections();
	}, [value]);

	const [showProfile, setShowProfile] = useState<boolean>(false);
	const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
	const [visible, setVisible] = useState<boolean>(false);

	// === Popover Menu
	const handleVisibleChange = (visible: boolean) => {
		setVisible(visible);
	};

	const menuItems: MenuProps['items'] = [...(i18n.languages || [])]
		.sort()
		.map((lang: string) => ({
			key: lang,
			onClick: () => changeLanguage(lang),
			icon: (
				<span style={{ marginRight: 8 }}>
					<Avatar size={16} src={`/images/flags/${lang}.svg`} />
				</span>
			),
			label: lang === 'en' ? 'English' : 'Tiếng Việt',
		}));

	const contentUser = (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Text
				style={{
					padding: '12px 20px',
				}}
			>
				<UserOutlined />
				<Text
					strong
					style={{
						padding: '4px 8px',
					}}
				>
					{user?.name}
				</Text>
			</Text>
			<div
				style={{
					borderTop: '1px solid #d9d9d9',
					padding: '4px',
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
				}}
			>
				<Button
					style={{ textAlign: 'left' }}
					icon={<SettingOutlined />}
					type="text"
					block
					onClick={() => {
						setVisible(false);
						setShowProfile(true);
					}}
				>
					Thông Tin Tài Khoản
				</Button>
				<Button
					style={{ textAlign: 'left' }}
					icon={<SettingOutlined />}
					type="text"
					block
					onClick={() => {
						setVisible(false);
						setShowChangePassword(true);
					}}
				>
					Đổi Mật Khẩu
				</Button>
				<Button
					style={{ textAlign: 'left' }}
					icon={<LogoutOutlined />}
					type="text"
					danger
					block
					onClick={() => logout()}
				>
					Đăng Xuất
				</Button>
			</div>
		</div>
	);

	return (
		<AntdHeader
			style={{
				backgroundColor: token.colorBgElevated,
				padding: '0 24px',
				position: 'sticky',
				height: '52px',
				lineHeight: '52px',
				top: 0,
				zIndex: 1,
				borderBottom: '1px solid rgb(217, 217, 217)',
			}}
		>
			<Row
				align="middle"
				style={{
					justifyContent: screens.sm ? 'space-between' : 'end',
				}}
			>
				<Col xs={0} sm={12}>
					<div style={{ fontSize: 14, fontWeight: 600, color: '#1677ff' }}>
						{user?.service_provider_name}
					</div>
				</Col>
				<Col>
					<Space size="middle" align="center">
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							type="default"
							icon={mode === 'light' ? <IconMoon /> : <IconSun />}
							onClick={() => {
								setMode(mode === 'light' ? 'dark' : 'light');
							}}
						/>
						<Dropdown
							menu={{
								items: menuItems,
								selectedKeys: currentLocale ? [currentLocale] : [],
							}}
						>
							<a
								style={{ color: 'inherit' }}
								onClick={(e) => e.preventDefault()}
							>
								<Space>
									<Avatar
										size={16}
										src={`/images/flags/${currentLocale}.svg`}
									/>
									<div
										style={{
											display: screens.lg ? 'block' : 'none',
										}}
									>
										{currentLocale === 'en' ? 'English' : 'Tiếng Việt'}
										<DownOutlined
											style={{
												fontSize: '12px',
												marginLeft: '6px',
											}}
										/>
									</div>
								</Space>
							</a>
						</Dropdown>
						<Popover
							placement="bottomRight"
							content={contentUser}
							trigger="click"
							overlayInnerStyle={{ padding: 0 }}
							overlayStyle={{ zIndex: 999 }}
							visible={visible}
							onVisibleChange={handleVisibleChange}
						>
							<Avatar
								style={{ cursor: 'pointer' }}
								// size="large"
								src={user?.avatar}
								alt={user?.name}
							/>
						</Popover>
					</Space>
				</Col>
			</Row>
			{showProfile && <ProfilePage onClose={() => setShowProfile(false)} />}
			{showChangePassword && (
				<ChangePasswordPage onClose={() => setShowChangePassword(false)} />
			)}
		</AntdHeader>
	);
};
